import { DEFAULT_PAYMENT, GERMANY } from '../../../constants';
import SeamlessImmutable from 'seamless-immutable';
import moment from 'moment';
import { baseResetUrl } from '../../../auth/utils/baseResetUrl';
import { ENV } from '../../../env';

const data = {
    languageId: null, // filled before creating a booking
    orderDate: undefined, // filled before creating a booking
    orderTime: undefined, // filled before creating a booking
    bookingTypeId: 1, // 1 - online
    paymentTypeId: DEFAULT_PAYMENT,
    autoCalculation: true, // may be updated before creating a booking
    termsAndPolicyAccepted: true,
    subscribed: false,
    baseUrl: baseResetUrl(),
    user: {
        register: false,
        salutationId: 1,
        customerTypeId: 1,
        titleId: null,
        address: {
            countryId: GERMANY,
        },
        saveToUserProfile: false,
    },
    transfers: [
        {
            arrivalTime: '00:00:00',
            adults: 1,
            children: 0,
            kids: 0,
            extras: {},
            pickUpFromTime: '01:00:00',
            pickUpUntilTime: '01:00:00',
            tripDate: null,
            tripTime: '00:00:00',
            flight: {
                date: null,
                time: '00:00:00',
            },
            addressFrom: {},
            addressTo: {},
        },
    ],
    busy: false,
    price: null,
    touched: {},
    company: {
        name: '',
    },
};

if (ENV.APP_DEBUG) {
    data.user.email = 'vitalii.retel@kawunu.com';
    data.user.firstName = 'Vitalii';
    data.user.lastName = 'Retel';
    data.user.address.street = 'Bandery';
    data.user.address.zip = '31100';
    data.user.address.place = 'Starokostyantyniv';
    data.user.mobile = '+380974322208';
    data.transfers[0].addressFrom = {
        id: 'MUC',
        placeValue: 'Airport Munich',
        name: 'Flughafen München',
        street: 'Nordallee 25',
        place: 'Freising',
        zip: '85356',
        countryId: GERMANY,
    };
    data.transfers[0].addressTo = {
        id: 2,
        placeValue: '94209, March',
        zip: '94209',
        place: 'March',
        street: 'Hello',
        countryId: GERMANY,
    };
    data.transfers[0].typeId = 2;
    data.transfers[0].arrivalTime = moment().startOf('hour').add(1, 'hours').format('HH:mm:[00]');
    data.transfers[0].tripDate = moment().add(1, 'days').format('YYYY-MM-DD');
    data.transfers[0].tripTime = moment().startOf('hour').format('HH:mm:[00]');
    data.transfers[0].flight = {
        flightNumber: 'PS950',
        flightDestination: 'Starokostyantyniv',
        date: moment().add(1, 'days').format('YYYY-MM-DD'),
        time: moment().startOf('hour').add(2, 'hours').format('HH:mm:[00]'),
    };
    data.termsAndPolicyAccepted = true;
}

const bookingInitialValue = SeamlessImmutable(data);

export { bookingInitialValue };
