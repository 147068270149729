import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PageTitle } from '../common/components/PageTitle';
import { EmailsService } from '../api/EmailsService';
import { Spinner } from '../common/components/Spinner';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const UnsubscribePage = () => {
    const { token } = useParams();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        EmailsService.unsubscribe(token)
            .then(() => setIsLoading(false))
            .catch((err) => {
                setError(err.message);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <PageTitle sx={{ pt: 8 }}>{t('unsubscribe.title')}</PageTitle>
            <Box sx={(theme) => theme.mixins.box(theme)}>
                <Box sx={{ pt: 0.5, pb: 1, maxWidth: '900px' }}>
                    <Typography variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                        {error ? t('unsubscribe.error') : t('unsubscribe.success')}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export { UnsubscribePage };
