import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import css from './BookingDetailsForm.module.css';
import { AlTextField } from '../../../common/components/AlTextField';

const UserName = ({
    booking,
    dictionary,
    onChange,
    onCheckboxChange,
    onNumberChange,
    bookingError,
    touchBooking,
    disabled,
}) => {
    const { t } = useTranslation();

    const salutations = dictionary && dictionary.salutations;
    const titles = dictionary && dictionary.titles;

    return (
        <>
            <Box sx={{ display: 'flex', pb: '10px' }}>
                <div className={css.item}>
                    <RadioGroup
                        name="user.salutationId"
                        value={booking.user.salutationId}
                        onChange={onNumberChange}
                        row
                    >
                        {salutations &&
                            salutations.map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    value={item.id}
                                    control={<Radio />}
                                    disabled={disabled}
                                    label={<Typography>{t(`salutation.${item.name}`)}</Typography>}
                                />
                            ))}
                    </RadioGroup>
                </div>
                <div className={css.item}>
                    {titles &&
                        titles.map((item) => (
                            <FormControlLabel
                                key={item.id}
                                control={
                                    <Checkbox
                                        checked={booking.user.titleId === item.id}
                                        onChange={(e, value) => onCheckboxChange(e, (value && item.id) || undefined)}
                                        name="user.titleId"
                                    />
                                }
                                disabled={disabled}
                                label={<Typography>{t(`title.${item.name}`)}</Typography>}
                            />
                        ))}
                </div>
            </Box>

            <div className={css.row}>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.firstName"
                        onChange={onChange}
                        value={booking.user.firstName || ''}
                        placeholder={t('first-name')}
                        alTooltip={t('tooltip.user.firstName')}
                        required={true}
                        onBlur={touchBooking}
                        error={bookingError('user.firstName')}
                        disabled={disabled}
                    />
                </div>
                <div className={css.item}>
                    <AlTextField
                        fullWidth
                        name="user.lastName"
                        onChange={onChange}
                        value={booking.user.lastName || ''}
                        placeholder={t('last-name')}
                        alTooltip={t('tooltip.user.lastName')}
                        required={true}
                        onBlur={touchBooking}
                        error={bookingError('user.lastName')}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

export { UserName };
