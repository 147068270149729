import { checkRequired, checkEmail, checkPhone } from '../../../common/utils/validation';
import { getIn } from '../../../common/utils/getIn';
import { setIn } from '../../../common/utils/setIn';
import { hasValidationError } from '../../../common/utils/hasValidationError';
import { CUSTOMER_TYPE_COMPANY, PAYMENT_INVOICE } from '../../../constants';

const detailsFormValidationErrors = ({ booking }) => {
    const validationErrors = (() => {
        const errors = {};

        const setError = (fields, checks) => {
            fields.forEach((field) => {
                setIn(errors, field, checks.map((check) => check(getIn(booking, field))).find(Boolean) || false);
            });
        };

        const requiredFields = [
            'termsAndPolicyAccepted',
            'user.firstName',
            'user.lastName',
            'user.address.street',
            'user.address.zip',
            'user.address.place',
        ];

        if (booking.paymentTypeId === PAYMENT_INVOICE) {
            requiredFields.push('company.name');
        }

        if (booking.company && booking.company.orderNumberRequired) {
            requiredFields.push('company.orderNumber');
        }

        if (booking.company && booking.company.customerCostCenterRequired) {
            requiredFields.push('company.costCenter');
        }

        if (booking.user.customerTypeId === CUSTOMER_TYPE_COMPANY) {
            requiredFields.push('user.companyName');
        }

        const requiredAndEmailFields = ['user.email'];
        const requiredAndPhoneFields = ['user.mobile'];
        const emailFields = ['user.ccemail'];
        const phoneFields = ['user.address.phone', 'passengerPhone'];

        setError(requiredFields, [checkRequired]);
        setError(requiredAndEmailFields, [checkRequired, checkEmail]);
        setError(requiredAndPhoneFields, [checkRequired, checkPhone]);
        setError(emailFields, [checkEmail]);
        setError(phoneFields, [checkPhone]);

        return errors;
    })();

    const isValid = !hasValidationError(validationErrors);

    return isValid ? null : validationErrors;
};

export { detailsFormValidationErrors };
