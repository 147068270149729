import { bookingInitialValue } from './bookingInitialValue';
import { TRANSFER, BACK_TRANSFER } from '../../models/transfer';
import { getIn } from '../../../common/utils/getIn';
import { isTransferLate } from '../../../common/utils/is-transfer-late';
import { SERVICES } from '../../../constants';

const BOOKING_ACTION = {
    UPDATE: 'update',
    UPDATE_USER: 'update_user',
    ADD_TRANSFER: 'add_transfer',
    REMOVE_TRANSFER: 'remove_transfer',
    RESET: 'reset',
    TOUCH: 'touch',
};

const notBookingDataChanges = ['touch', 'price'];

function touch(previous, keyArray, value) {
    if (value && typeof value === 'object') {
        return Object.keys(value).reduce((obj, key) => touch(obj, [...keyArray, key], value[key]), previous);
    }
    return previous.setIn(['touched', ...keyArray], true);
}

const bookingReducer = (previous, action) => {
    if (action.type === BOOKING_ACTION.RESET) {
        return bookingInitialValue;
    }

    if (previous.busy && action.name !== 'busy') {
        return previous;
    }

    switch (action.type) {
        case BOOKING_ACTION.UPDATE:
            const path = action.name.split('.');
            if (path[0] === 'transfers') {
                previous = transfersRelated({ previous, path, action });
            }
            previous = previous.setIn(path, action.value);

            // update fields related to current time
            if (!notBookingDataChanges.includes(action.name) && action.serverDate) {
                previous = currentTimeRelated(previous, action.serverDate);
            }

            return touch(previous, path, action.value);

        case BOOKING_ACTION.ADD_TRANSFER:
            const firstTransfer = previous.getIn(['transfers', 0]);
            const secondTransfer = {
                ...firstTransfer,
                addressFrom: firstTransfer.addressTo,
                addressTo: firstTransfer.addressFrom,
                flight: bookingInitialValue.getIn(['transfers', 0, 'flight']),
                typeId: BACK_TRANSFER[firstTransfer.typeId],
            };
            return previous.setIn(['transfers', 1], secondTransfer);

        case BOOKING_ACTION.REMOVE_TRANSFER:
            return bookingReducer(previous, {
                type: BOOKING_ACTION.UPDATE,
                name: 'transfers',
                value: [previous.getIn(['transfers', 0])],
            });

        case BOOKING_ACTION.TOUCH:
            return touch(previous, action.name.split('.'), action.value);

        case BOOKING_ACTION.UPDATE_USER:
            if (!action.value) {
                previous = previous.setIn(['agencyId'], undefined);
                return previous.setIn(['user', 'id'], undefined);
            }
            [
                'firstName',
                'lastName',
                'companyName',
                'salutationId',
                'customerTypeId',
                'titleId',
                'mobile',
                'email',
                'ccemail',
                'address.zip',
                'address.place',
                'address.street',
                'address.phone',
                'address.countryId',
            ].forEach((key) => {
                const value = getIn(action.value, key);
                previous = bookingReducer(previous, { type: BOOKING_ACTION.UPDATE, name: `user.${key}`, value });
            });
            previous = bookingReducer(previous, { type: BOOKING_ACTION.UPDATE, name: 'user.password', value: '' });
            previous = bookingReducer(previous, {
                type: BOOKING_ACTION.UPDATE,
                name: 'user.passwordRepeat',
                value: '',
            });
            // update hidden fields
            previous = previous.setIn(['user', 'id'], action.value.id);
            previous = previous.setIn(['user', 'customerTypeId'], action.value.customerTypeId);
            previous = previous.setIn(['agencyId'], action.value.agencyId);
            return previous;

        default:
            console.error(`Unknown action type "${action.type}"`);
            return previous;
    }
};

const transfersRelated = ({ previous, path, action }) => {
    // not interested if the whole transfers or transfer is updated
    if (path.length <= 2) {
        return previous;
    }
    // update the second transfer if the first one is updated
    if (parseInt(path[1], 10) !== 0) {
        return previous;
    }

    const property = path[path.length - 1];

    // auto clean serviceTypeId when typeId is changed
    if (property === 'typeId' && previous.getIn(path) !== action.value) {
        previous = typeIdRelated({ previous, path, action });
    }
    // auto clean price when serviceTypeId is set
    if (property === 'serviceTypeId') {
        previous = serviceTypeIdRelated({ previous, path, action });
    }
    // copy extras
    if (path.includes('extras')) {
        previous = extrasRelated({ previous, path, action });
    }

    return previous;
};

const typeIdRelated = ({ previous, path, action }) => {
    previous = bookingReducer(previous, {
        type: BOOKING_ACTION.UPDATE,
        name: [...path.slice(0, -1), 'serviceTypeId'].join('.'),
        value: undefined,
    });
    previous = bookingReducer(previous, {
        type: BOOKING_ACTION.UPDATE,
        name: [...path.slice(0, -1), 'serviceType'].join('.'),
        value: undefined,
    });
    if (previous.getIn(['transfers', 1])) {
        if (action.value === TRANSFER.SPECIAL) {
            // remove second transfer
            previous = bookingReducer(previous, {
                type: BOOKING_ACTION.REMOVE_TRANSFER,
            });
        } else {
            // update second transfer
            const firstTransfer = previous.getIn(['transfers', 0]);
            const currentSecondTransfer = previous.getIn(['transfers', 1]);
            const secondTransfer = {
                ...currentSecondTransfer,
                addressFrom: firstTransfer.addressTo,
                addressTo: firstTransfer.addressFrom,
                typeId: BACK_TRANSFER[action.value],
            };
            previous = previous.setIn(['transfers', 1], secondTransfer);
        }
    }
    return previous;
};

const serviceTypeIdRelated = ({ previous, action }) => {
    previous = bookingReducer(previous, { type: BOOKING_ACTION.UPDATE, name: 'price', value: undefined });
    if (previous.getIn(['transfers', 1])) {
        // update serviceTypeId in the second transfer
        previous = bookingReducer(previous, {
            type: BOOKING_ACTION.UPDATE,
            name: 'transfers.1.serviceTypeId',
            value: action.value,
        });
    }
    return previous;
};

const extrasRelated = ({ previous, path, action }) => {
    if (!previous.getIn(['transfers', 1])) {
        return previous;
    }
    // update serviceTypeId in the second transfer
    return bookingReducer(previous, {
        type: BOOKING_ACTION.UPDATE,
        name: `transfers.1.${path.slice(2).join('.')}`,
        value: action.value,
    });
};

// for changes related with current date/time of booking creating
const currentTimeRelated = (previous, currentDate) => {
    const transfers = previous.getIn(['transfers']);

    if (!transfers) {
        return previous;
    }

    // remove all late services and check them again with current time
    transfers.forEach((transfer, index) => {
        if (transfer.extras?.[SERVICES.LATE_TO_MUNICH]) {
            previous = previous.setIn(['transfers', index, 'extras', SERVICES.LATE_TO_MUNICH], 0);
        }

        if (transfer.extras?.[SERVICES.LATE_FROM_MUNICH]) {
            previous = previous.setIn(['transfers', index, 'extras', SERVICES.LATE_FROM_MUNICH], 0);
        }

        if (transfer.extras?.[SERVICES.LATE]) {
            previous = previous.setIn(['transfers', index, 'extras', SERVICES.LATE], 0);
        }
    });

    if (transfers[0].typeId !== TRANSFER.SPECIAL) {
        transfers.forEach((transfer, index) => {
            const isToAirport = [TRANSFER.TO_MUNICH, TRANSFER.TO_NUREMBERG].includes(transfer.typeId);
            const transferTime = isToAirport ? transfer.arrivalTime : transfer.flight.time;

            if (isTransferLate(transferTime, transfer.flight.date, currentDate)) {
                const isTransferToMunich = transfer.typeId === TRANSFER.TO_MUNICH;
                const isTransferFromMunich = transfer.typeId === TRANSFER.FROM_MUNICH;

                if (isTransferToMunich) {
                    previous = previous.setIn(['transfers', index, 'extras', SERVICES.LATE_TO_MUNICH], 1);
                } else if (isTransferFromMunich) {
                    previous = previous.setIn(['transfers', index, 'extras', SERVICES.LATE_FROM_MUNICH], 1);
                } else {
                    previous = previous.setIn(['transfers', index, 'extras', SERVICES.LATE], 1);
                }
            }
        });
    }

    return previous;
};

export { bookingReducer, BOOKING_ACTION };
