import { simpleRequest } from './request';

const EmailsService = {
    getHtmlById: (id) =>
        simpleRequest({
            method: 'get',
            url: `/email-log/${id}/html`,
        }),
    unsubscribe: (token) =>
        simpleRequest({
            method: 'post',
            url: '/email/unsubscribe',
            data: { token },
        }),
};

export { EmailsService };
