const DEFAULT_PAYMENT = 7;
const PAYMENT_CASH = 1;
const PAYMENT_INVOICE = 10;

// creditcard, paypal, sofort
const ONLINE_PAYMENT_TYPES = [7, 9, 15, 17];

const GERMANY = 82;

const CUSTOMER_PRIVATE_ID = 1;
const CUSTOMER_COMPANY_ID = 2;
const OFFICE_PHONE = '+49 170 3722220';

const LATE_MINUTE_HOUR = {
    FROM: 19,
    TO: 11,
};

const SHORT_TERM_HOUR = {
    FROM: 13,
};

const GERMAN_TIMEZONE = 'Europe/Berlin';

// TODO move other extra services to this constant
const SERVICES = {
    LATE_TO_MUNICH: 'LATE_BOOKING_TO_MUNICH',
    LATE_FROM_MUNICH: 'LATE_BOOKING_FROM_MUNICH',
    LATE: 'LATE_BOOKING',
    CHSEAT0: 'CHSEAT0',
};

const DISABLED_SERVICES = {
    [SERVICES.CHSEAT0]: 'extras-info.CHSEAT0',
};

const CUSTOMER_TYPE_PRIVATE = 1;
const CUSTOMER_TYPE_COMPANY = 2;

export {
    DEFAULT_PAYMENT,
    PAYMENT_CASH,
    PAYMENT_INVOICE,
    GERMANY,
    CUSTOMER_PRIVATE_ID,
    CUSTOMER_COMPANY_ID,
    OFFICE_PHONE,
    LATE_MINUTE_HOUR,
    GERMAN_TIMEZONE,
    SERVICES,
    ONLINE_PAYMENT_TYPES,
    SHORT_TERM_HOUR,
    DISABLED_SERVICES,
    CUSTOMER_TYPE_PRIVATE,
    CUSTOMER_TYPE_COMPANY,
};
